import $ from 'jquery';
import LucronPaymentMethods from './lucronPaymentMethods';
import DealUnits from './units';

$(()=> {
  $(document).on('rp-tabs:loaded', (event, data) => {
    const $form = data.$root.find('#deal-future-form');
    if ($form.exists()) {
      new DealUnits($form);
      if ($form.attr('data-is-lucron') === 'true') {
        new LucronPaymentMethods($form);
      }
    }
  });
});
